.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
}

.carDetailContainer {
  padding-top: 50px;
  padding-bottom: 30px;
  background: #FFFFFF;
}

.carDetailsTable {
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  .carDetailsFooter {
    margin-top: 8rem;

    @media (max-width: 991px) {
      display: none;
    }
  }
}
