$color_1: #07679b;
$color_2: #666666;
$color_3: #dbdbdb;
$color_4: #fff;
$color_5: #005f83;
$color_6: #0081c6;
$font_family_1: 'source-sans-pro, sans-serif';
$font_family_2: 'source-sans-pro, sans-serif';
$background_color_1: #FFF;
$background_color_2: white;
$background_color_3: #f2f2f2;
$background_color_4: #00657F;
$background_color_5: #f3f4f4;
$background_color_6: #0071b7;
$background_color_7: #ee1c2e;
$background_color_8: #f4f4f4;
$border_color_1: transparent;
$border_bottom_color_1: rgba(0, 0, 0, 0.25);
$border_bottom_color_2: #fff;

$main_blue: #00657F;
$soft_gray: #F4F4F4;
$white: #fff;

@font-face {
  font-family: 'Futura PT';
  font-style: normal;
  font-weight: 200;
  src: local('Futura PT Light'),
    url('../../assets/FuturaCyrillicLight.woff') format('woff');
}

@font-face {
  font-family: 'Futura PT';
  font-style: normal;
  font-weight: 400;
  src: local('Futura PT Medium'),
    url('../../assets/FuturaCyrillicBook.woff') format('woff');
}

@font-face {
  font-family: 'Futura PT';
  font-style: normal;
  font-weight: 500;
  src: local('Futura PT Demi'),
    url('../../assets/FuturaCyrillicDemi.woff') format('woff');
}

@font-face {
  font-family: 'Futura PT';
  font-style: normal;
  font-weight: 700;
  src: local('Futura PT Bold'),
    url('../../assets/FuturaCyrillicBold.woff') format('woff');
}

@font-face {
  font-family: 'Futura PT';
  font-style: normal;
  font-weight: 900;
  src: local('Futura PT Heavy'),
    url('../../assets/FuturaCyrillicHeavy.woff') format('woff');
}

body {
  background-color: $background_color_1;
  font-family: source-sans-pro, sans-serif;
  font-size: 13px;
}
h1 {
  font-family: $font_family_2;
  font-size: 46px;
  color: #00657F;
  font-weight: 400;
}
h2 {
  font-family: $font_family_1;
  font-size: 28px !important;
  font-weight: 300;
  margin-bottom: 0px;
}
.modal-title {
  font-size: 18px;
  text-transform: uppercase;
  font-family: $font_family_1;
}
h3 {
  font-size: 16px;
}
.h3 {
  font-size: 20px;
}
h4 {
  font-size: 12px;
}
.h4 {
  font-size: 12px;
}
h5 {
  font-size: 10px;
}
.h5 {
  font-size: 10px;
}
.lead {
  font-size: 24px;
}
a {
  color: $color_1;
}
table {
  table-layout: fixed;
  tbody {
    th {
      font-weight: normal;
    }
    tr {
      th {
        &:first-child {
          text-align: left;
        }
      }
    }
  }
  tfoot {
    tr {
      th {
        &:first-child {
          text-align: left;
        }
      }
    }
  }
}
table.table-bordered {
  tfoot {
    td {
      font-weight: bold;
      border-top: 2px solid #dee2e6;
    }
    th {
      font-weight: bold;
      border-top: 2px solid #dee2e6;
    }
  }
  th {
    word-break: break-word;
  }
}
button.btn-sm {
  font-size: 80%;
}
.input-well {
  .button.btn-sm {
    font-size: 80%;
  }
  background-color: $background_color_2;
  border-radius: 5px;
  box-shadow: 0 20px 41px 0 rgba(0, 0, 0, 0.1);
  padding: 17px;
  .form-group {
    background-color: white;
    p {
      margin-bottom: 1rem;
    }
  }
  form {
    .form-group {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  label {
    font-weight: 400;
    font-size: 14px;
    color: #2a2a2a;
    text-transform: uppercase;
  }
  .label-style {
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: #000;
  }
  .btn {
    display: inline-block;
    border: 1px solid #00657F;
    font-weight: 400;
    font-size: 14px;
    background-color: white;
    color: $main_blue;
    border-radius: 5px;
    height: 40px;
  }
  .btn.active {
    background-color: $main_blue;
    color: white;
    border: 1px solid $main_blue;
  }
  .btn:focus {
    box-shadow: 0 0 0 0.2rem #00657F !important;
  }

  .btn-block-container {
    .filter-label {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      position: relative;
      .name {
        font-size: 14px;
      }
      .switch {
        position: absolute;
        right: 0;
      }
    }
    .btn {
      display: block;
      width: 100%;
      margin-bottom: 6px;
      text-align: left;
      .badge {
        background-color: $background_color_2;
        border: 1px solid #dbdbdb;
        margin-right: 5px;
        img {
          width: 20px;

          padding-right: 17px;
        }
      }
      .filter-label {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;

        .name {
          display: inline-flex;
          white-space: nowrap;
        }
        .switch {
          position: absolute;
          left: 70%;
          .react-switch-bg {
            width: 50px !important;
          }
        }
      }
      .filter-switch {
        display: flex;
      }
      .first {
        margin-bottom: 10px;
      }
    }
  }
  .btn-grid-container {
    display: flex;
    flex-wrap: wrap;
    button {
      width: 48%;
      margin: 2%;
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      &:nth-of-type(2n + 1) {
        margin-left: 0;
      }
    }
  }
  .btn-ae {
    padding: 10px 40px;
    border-radius: 0;
    border: 1px solid #00657F;
    color: #00657F;
    font-weight: 600;
    background-color: $background_color_1;
  }
}
.btn-default {
  border: 1px solid #ced4da;
}
strong {
  font-weight: 500;
}
hr {
  border-top: 2px solid rgba(0, 0, 0, 0.8);
  margin: 0;
  padding: 0;
}
.arrow-up {
  top: calc((1rem - 1px) * -1);
  position: absolute;
  display: block;
  width: 1rem;
  height: 1rem;
  margin: 0 0.3rem;
  &:before {
    position: absolute;
    display: block;
    content: '';
    border-color: $border_color_1;
    border-style: solid;
    top: 0;
    border-width: 0 1rem 1rem 1rem;
    border-bottom-color: $border_bottom_color_1;
  }
  &:after {
    position: absolute;
    display: block;
    content: '';
    border-color: $border_color_1;
    border-style: solid;
    top: 1px;
    border-width: 0 1rem 1rem 1rem;
    border-bottom-color: $border_bottom_color_2;
  }
}
.grey {
  background-color: $background_color_5 !important;
}
.white {
  background-color: white !important;
}

.bottom-thick-margin {
  border-bottom: 2px solid #000000;
}
.color-primary {
  color: $color_6;
}
.btn-ae {
  padding: 10px 40px;
  border-radius: 0;
  font-weight: 600;
  background-color: $background_color_1;
  border: 1px solid #00657F;
  color: #00657F;
  &:hover {
    border-radius: 0;
    color: $color_4;
    font-weight: 600;
    text-decoration: none;
    background-color: #00657F;
  }
}

.btn-aps {

  padding: 10px 40px;
  border-radius: 0;
  color: $color_4;
  font-weight: 600;
  background-color: $main_blue;
  border: none;
  font-size: 16px;
  text-transform: uppercase;

  font-family: source-sans-pro, sans-serif;
  font-size: 17px !important;
  font-weight: 700;
  line-height: 20px;
  text-align: center;


  &:hover {
    display: inline-block;
    font-weight: 400;
    font-weight: 600;
    background-color: #00657F !important;
    color: $color_4;
  }

  &.white {
       background-color:#fff;
       color:#00657F;

       &:hover {
              background-color:#fff !important;
              color:#00657F;
       }
  }
}

.btn-aps-secondary {
  padding: 10px 20px;
  border-radius: 0;
  color: $background_color_4;
  font-weight: 600;
  background-color: $color_4;
  border: none;

  &:hover {
    border: 2px solid #00657F;
    font-weight: 600;
    background-color: $color_4;
    color: #00657F;
  }
}

.btn-100 {
  width: 100%;
}

.PageLayout {
  a.btn-ae {
    &:hover {
      border-radius: 0;
      color: $color_4;
      font-weight: 600;
      text-decoration: none;
      background-color: $background_color_6;
    }
  }
}
a.btn-ae {
  &:hover {
    border-radius: 0;
    color: $color_4;
    font-weight: 600;
    text-decoration: none;
    background-color: $background_color_6;
  }
}

// .select-ae {
// 	-webkit-appearance: none;
// 	-moz-appearance: none;
// 	-ms-appearance: none;
// 	appearance: none;
// 	background-repeat: no-repeat;
// 	background-position: right 15px top 11px, right 10px top 1em;
// 	background-size: 15px 15px, 15px 15px;
// 	background-image: url(../../assets/images/icons/Link-arrow.png);
// }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS */
  .select-ae {
    background-size: 0;
  }
}
.btn-arrow {
  text-align: start;
  background: none;
  border: none;
  color: #00657F;
  font-weight: 400;
  font-size: 14px;
}
#vehicle-sort-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../../assets/images/Triangle.svg);
  background-position: 95%;
  background-size: 0.5em 0.5em;
  background-repeat: no-repeat;
  background-color: $background_color_1;
  margin-right: 1em;
}

.selected-subText {
  display: flex;
  margin: 10px;
  font-weight: 400;
  color: #666666;
  font-size: 12px;
}
.shadowed {
  box-shadow: 0 20px 41px 0 rgba(0, 0, 0, 0.1);
}

.bold {
  font-weight: 700 !important;
}
.light {
  font-weight: 400 !important;
}
.small-centered {
  margin-left: auto;
  margin-right: auto;
  width: 60%;

  @media (max-width: 768px) {
    width: 95%;
    text-align: left;
  }
}
.header, .lead, .evHead, .title-map, .subtitle-map, .title-page, .pad-bottom {

  @media (max-width: 768px) {
    text-align: left !important;
  }
}


@media (min-width: 576px) {
  .modal-dialog {
    max-width: 800px;
    margin: 1.75rem auto;
  }
  .modal-dialog {
    max-width: 800px;
    margin: 1.75rem auto;
  }
}

.incentive-label-select {
  font-size: 14px;
  text-transform: uppercase;
  color: #000;
}
.container-fluid-tight {
  padding: 2rem 8rem;
}

@media (max-width: 576px) {
  .container-fluid-tight {
    padding: 2rem 2rem !important;
  }
}

.grey {
  background-color: #f3f3f3 !important;
}
.bold {
  font-family: 'Gotham Bold';
}

.title-head {
  font-weight: 700;
  font-family: 'Gotham Bold';
  font-size: 32px;
}
.title-sub {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
}
.btn-maps {
  padding: 10px 40px;
  border-radius: 0;
  color: $color_4;
  font-weight: 600;
  background-color: $background_color_4;
  border: none;
}

@media (min-width: 768px) {
  .map-options {
    .zip-options {
      display: flex;
      flex-direction: column;
    }
    .power-options {
      margin-left: 50px;
    }
  }
  #select-vehicle-power-filter,
  #travel-radius-map-address-input {
    font-weight: 400;
    font-size: 12px;
    color: #2A2A2A;
    width: 218px;
  }
  .btn-maps {
    margin-top: 26px;
    margin-left: 30px;
    width: 240px;
  }
  @media (max-width: 576px) {
    .btn-maps {
      margin-top: auto;
      margin-left: 0px !important;
      width: 240px;
    }
  }
}
.input-working-zipcode {
  margin-top: 0 !important;
  .map-options {
    margin-top: 0 !important;
  }
  .form-group {
    margin-bottom: 0 !important;
  }
    @media (max-width: 576px) {
      .form-group {
        margin-bottom: 10px !important;
      }
    }
}

.input-working-zipcode label {
  font-family: 'Gotham Bold';
  text-transform: capitalize;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
  margin-bottom: 8px;
}
.evHead {
  display: flex;
  justify-content: center;
  font-family: 'Gotham Bold';
  font-size: 42px;
}
.compare-select-title {
  font-size: 12px;
  color: #2a2a2a;
  text-transform: uppercase;
}
.graph-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  margin: 0 0 20px;
}

.bottom-margin {
  margin-bottom: 30px !important;
}
.top-margin {
  margin-top: 30px !important;
}

.MatchScoreOptions.margin-top {
  margin-top: 24px !important;

  @media (max-width: 768px) {
    margin-top: 0 !important;
  }
}

.apply-button-filter-modal {
  width: 100%;
  height: 54px;

  background: #00657F;

  font-family: source-sans-pro;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #fff;
  border: none;
}

.MatchScoreOptions {
  row-gap: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid #AEAEAE;



  @media (max-width: 911px) {
    width: 100%;
    border: none;
    background: #F4F4F4;
    box-shadow: none;
  }
}
.form-group {
  background-color: white;
}
.show-hide-btn-arrow {
  width: 100%;
  border-radius: 0;
  font-weight: 600;
  padding: 0.75rem 2rem;
  color: #2c61ae !important;
  background-color: $background_color_5 !important;
  border: 1px solid $color_3 !important;
  background: url(../../assets/images/blue-triangle.svg);
  background-position: 95%;
  background-size: 0.75em 0.75em;
  background-repeat: no-repeat;
}
.graph-sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: start;
  color: #2a2a2a;
}
.compare-title {
  font-size: 24px;
  font-weight: 400;
}

.line-box {
  display: flex !important;
}
.savings-info {
  font-weight: 400;
  font-size: 18px;
  margin-top: 5%;
}

.chart-info {
  width: 85%;
}
.img-fluid {
  width: 100%;

  @media (max-width: 991px) {
    &.ev-page {
      width: 100% !important;
      margin: 16px 0px;
    }
    width: 70%;
  }
}
.unClick {
  pointer-events: none !important;
}

#ev-jumbotron-title,
#charging-map,
#incentives,
#EVs,
#home-charging-advisor,
#events {
  background-color: $background_color_8 !important;
}

.map-options {
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: space-around;
  padding: 0 15px;
  gap: 40px;

  @media(max-width: 911px) {
    justify-content: flex-start;
  }

  label {
    font-family: source-sans-pro, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0px;
    margin-bottom: 8px;
    text-transform: uppercase;
    color: #666666;
  }

  input {
    max-width: 300px;

    @media (max-width: 991px) {
      max-width: 100%;
    }
  }

  .power-options {
    margin: 0;
  }

  .form-check {
    margin-top: 0;
  }
    @media (max-width: 576px) {
      .form-check {
          margin-bottom: 5px;
        }
    }

  button {
    width: 100%;
    max-width: 300px;
    height: 42px;
    padding: 0;

    font-family: source-sans-pro;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    background-color: #00657F;

    margin: 12px 0 0;

    &:hover {
      background-color: #00657F;
    }
  }
}

.switch {
  .react-switch-bg {
    border: 1px #00657F solid;
  }

  .react-switch-handle {
    top: 2px !important;
    left: 1px !important;
    height: 24px !important;
    width: 24px !important;
  }
}

input[type='checkbox'] {
  accent-color: #00657F;
  outline: 1px auto #A0A0A0;
}


.form-check-input {
  width: 24px;
  height: 24px;
}


.availableNowContainer {
  display: flex;
  align-items: center;

  input {
    outline: none !important;
    margin-right: 8px !important;
  }
  label {
    color: #00657F !important;
    margin-left: 16px !important;
    margin-top: 7px !important;
  }
}

.evContainer {
  row-gap: 5px;
  display: flex;
  flex-direction: column;
}

.backButton {
  color: #0000008a;
  display: flex;
  gap: 5px;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: Roboto, sans-serif;
}

.evPageTitle {
  font-weight: 500;
  font-family: source-sans-pro, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;

}

.containerPaymentButtons {
  display: flex;
  gap: 24px;

  button {
    width: 50%;
  }
}

.EVIncentives {
  background-color: white !important;
}

.graph-container {
  display: flex;
  flex-direction: column;
  row-gap: 27px;
}

.title-map {
  color: #00657F;
  text-align: center;
  font-family: source-sans-pro;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px; /* 121.739% */
  text-transform: uppercase;
}

.subtitle-map {
  color: #2a2a2a;
  text-align: center;
  font-family: source-sans-pro, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px; /* 128.571% */

  max-width: 751px;
}

.align-items {
  align-items: center;
}

.title-page {
  font-family: source-sans-pro;
  font-size: 46px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
}

.container-cost {
  margin-bottom: 143px;

  &.ev-page {
    @media (max-width: 991px) {
      background: #F4F4F4;
      margin-bottom: 0;
      padding-bottom: 64px !important;
    }
  }
}

.currency-wrap {
  position: relative;
}

.currency-code {
  position: absolute;
  left: 18px;
  top: 20px;
}

.unit-code {
  position: absolute;
  left: 90px;
  top: 20px;
}

.text-currency {
  padding: 10px 30px;
  border: solid 1px #ccc;
  border-radius: 5px;
}
