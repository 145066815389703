.ChargingStationsMapLegend {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 15px;
  font-size: 0.75rem;
  width: 100%;
  display: flex;
  justify-content: center;
  &.vertical {
    flex-direction: column;
  }

  @media (max-width: 991px) {
    margin-top: 24px;
  }

  .charger-type {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px 20px;

    .textContainer {
      display: flex;
      flex-direction: column;
    }

    @media (max-width: 991px) {
      .textContainer {
        flex-direction: row;
      }

      b {
        font-size: 14px;
        font-weight: 700;
        line-height: 17.6px;
        text-align: left;

        margin-right: 8px;
      }

      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 17.6px;
        text-align: left;

      }
    }
  }

  .charger-type:last-child {
    margin-bottom: 0;
  }

  .charger-type img {
    width: 25px;
    left: 0;
    top: 0;
    margin-right: 8px;

    @media (max-width: 991px) {
      width: 28px !important;
    }
  }

  @media (max-width: 991px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    .charger-type {
      margin: 0px !important;
    }
  }
  
  @media (max-width: 450px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
  
    .charger-type {
      margin: 6px 0px !important;
    }
  }
}
