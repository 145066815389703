@import 'src/client_customizations/scss/client_master.scss';

/* Apply yellow span underline for mobile views */
.Header {
  background-color: $white;
  font-family: 'source-sans-pro' !important;


  .mobile-nav {
    width: 100%;
    height: 100vh;
    transition: transform 0.3s ease;
    position: fixed;
    right: 0; 
    top: 0; 
    z-index: 1001;
    transform: translateX(100%); 
    

    &.show {
      transform: translateX(0); 
      overflow: hidden;
      background: rgba(0,0,0,0.5);
    }

    .mobile-nav-container {
      width: 90%;
      height: 90%;
      background: #FAFAFA;
      right: 0;
      position: absolute;

      .mobile-nav-header {
        width: 100%;
        height: auto;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding: 20px;

        img {
          width: 48px;
          height: auto;
        }
      }

      .mobile-nav-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;

        .navbar-nav {
          gap: 24px;
          align-items: flex-start;
          width: 70%;

          li {
            font-family: source-sans-pro;
            font-size: 22px;
            font-weight: 400;
            line-height: 27.65px;
            text-align: left;
            text-transform: lowercase;

            ::first-letter {
              text-transform: uppercase;
            }

            .language-button  {
              background: transparent;
              border: none;
              color: #00657F;
              font-family: source-sans-pro;
              font-size: 22px;
              font-weight: 400;
              line-height: 27.65px;
              text-align: left;
              text-transform: uppercase;
              padding: 0;

              &.active {
                font-weight: 700;
              }
            }
          }
        }
      }

    }
  }

  @media (max-width: 911px) {
    .homepagelogo {
      width: 117px !important;
      height: 48px !important;

    }
  }

  a {
    color: $main_blue;
  }

  .bottomHeader {
    background-color: black;
    display: flex;
    justify-content: center;
    color: white;
    margin: 0;
    padding: 5px;
  }

  .mobile-top-header {
    margin: 0;
  }
   
  .navbar {
    padding: 12px 62px !important;

    @media (max-width: 911px) {
      padding: 8px 16px !important;
    }

 

    .navbar-brand {
      padding-top: 0;
      padding-bottom: 0;
      img {
        width: 160px;
      }
    }
    .navbar-collapse {
      justify-content: end;
      a.active {
        span {
          // color: $Header-navbar-link-color;
          border-bottom: 2px solid #00657F;
          text-decoration: none;
        }
      }

      .navbar-nav {
        display: flex;
        column-gap: 36px;
        align-items: center;
      }

      .dropdown-item {
        border-radius: 0;
      }
    }

    .dropdown-menu {
      top: 50px;
      border-radius: 3px;

      .dropdown-item {
        font-size: 13px;

        &.item {
          color: white;
        }
        &.off {
          margin-left: 4px;
        }
        &.active,
        &:active {
          border-left: 3px solid white;
          background-color: transparent;
          color: $Header-navbar-link-color;
        }
      }
    }
  }
  .nav-item {
    font-family: source-sans-pro;
    font-size: 20px;
    font-weight: 450;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
  }
  .input-zipcode {
    justify-content: flex-end;
    background-color: white;
    margin: inherit;
    .input-group-text {
      background-color: white;
      border: none;
    }
    .btn {
      color: #005f83;
    }
  }
  li {
    display: flex;
  }
  .dropdown-menu {
    background-color: #232425;
    min-width: 0;
    .dropdown-item:hover {
      background-color: #0073b1;
      color: #f9f9f9;
    }
    .dropdown-item {
      white-space: normal;
    }
  }
  span {
    font-size: 20px;
    font-weight: 450;
  }
  .form-inline {
    flex-flow: row;
  }
}
.skip-to-content-link {
  position: absolute;
  top: -1000px;
  left: -1000px;
  height: 1px;
  width: 1px;
  text-align: left;
  overflow: hidden;
}
a.skip-to-content-link {
  &:active {
    left: 75px;
    top: 20px;
    width: auto;
    height: auto;
    overflow: visible;
  }
  &:focus {
    left: 75px;
    top: 20px;
    width: auto;
    height: auto;
    overflow: visible;
  }
  &:hover {
    left: 75px;
    top: 20px;
    width: auto;
    height: auto;
    overflow: visible;
  }
}
.navbar-toggler {
  border: none;
  border-color: $Header-navbar-toggler-border-color;
  color: $Header-navbar-toggler-color;
  .navbar-toggler-icon {
    height: 1.25em;
    width: 1.25em;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
}
@media (min-width: 992px) {
  .Header {
    .input-zipcode {
      padding: 0.5rem;
    }
    .navbar {
      max-height: 130px;
      padding: 0.75rem;
      .navbar-collapse {
        a {
          font-weight: 450;
          line-height: 26px; /* 130% */
          text-decoration: none;
          text-transform: uppercase;
          font-family: source-sans-pro;
        }
        a.active {
          font-weight: bold;
        }
      }
    }
  }
}

@media (max-width: 766px) {
  .Header {
    #update-zipcode-button {
      margin-left: 8px;
    }
    .input-group-text {
      padding: 3px 3px;
    }
  }
}

.language-button {
  border: 3px solid #00657F;
  background: #ffff;
  color: #00657F;
  font-family: source-sans-pro, sans-serif ;
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  padding: 8px 12px;
  cursor: pointer;
  text-transform: uppercase;

  &.active {
    background: #00657F;
    color: #fff;
  }
}