@import 'src/client_customizations/scss/client_master.scss';

.IncentiveCard {
  margin-right: 1%;
  margin-bottom: 1%;
  border-radius: $IncentiveCard-card-border-radius;
  border: none;
  border-top: 8px solid $main_blue;
  box-shadow: 0px 3px 22px 0px #0000001A; //0px 20px 41px 0px #0000001A
  background-color: white;
  width: 255px;
  min-height: 369px;

  display: flex;
  flex-direction: column;

  &:hover {
    p {
      text-decoration-color: #00657F !important;
      text-decoration-line: underline;
    }
  }

  @media(max-width: 991px) {
    width: 90%;
  }
}
.IncentiveCardBody {
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  text-align: center;
  color: black;
  border-radius: 0;

  .card-title {
    height: 55px;
    font-family: 'source-sans-pro' !important;
    font-size: 32px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 16px;
  }

  .light {
    font-family: 'source-sans-pro, sans-serif, Open Sans', Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;
    max-width: 80%;
    margin: 24px auto 0;
  }

  img {
    margin-top: -12px;
    margin-bottom: -16px;
    width: 60px;
  }
}
.IncentiveCardTop {
  padding: 1rem;
  text-align: center;
  font-size: 14px;
  color: $IncentiveCard-card-body-color;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  border-radius: 0;
  font-weight: 400;
  .card-title {
    margin-top: 1rem;
    color: $IncentiveCard-card-title-color;
  }
  span {
    font-family: 'source-sans-pro, sans-serif, Open Sans', Helvetica, sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: #2a2a2a;
  }
}
.hrContainer {
  margin-top: auto;
}
hr {
  width: 100%;
  border-top: 2px solid #dadada;
}

.IncentiveCardBottom {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  min-height: 72px;
  padding: 1.5rem 1rem;

  @media (max-width: 991px) {
      margin: 0 !important;
      padding: 22px 16px 16px;;
  }
}

.IncentiveCardBottomShowMore {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  min-height: 72px;
  padding: 1.5rem 0rem 0rem;
  margin: 0;
  display: flex;
  gap: 8px; 

  @media (max-width: 992px) {
      margin: 2.5rem 2.5rem;
    }
}

.IncentiveCardShowMore {
  padding: 22px 16px;
  font-family: source-sans-pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  color: #2A2A2A;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.displayNone {
  display: none !important;
}

.showBtn {
  width: 100%;
  text-align: center;
  border: none;
  margin-top: 8px;
  background-color: transparent;
  border: 2px solid #00657F; 
  padding: 8px 0; 

  .readFull {
    font-family: source-sans-pro, sans-serif;
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    
    color: #00657F;
    text-transform: uppercase;
  }
}

.showAllCard {
  min-height: 369px !important;
  height: auto !important;
  max-height: 1000px !important;

  .description {
    min-height: 88px !important;
    height: auto !important;
    max-height: 1000px !important;
  }
}

@media (min-width: 768px) {
  .IncentiveCardBody {
    padding: 0rem 0.5rem 0.5rem 0.5rem;
  }

 
}
