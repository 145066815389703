.form-check-label {
  margin: 4px 0 0 6px !important;
}

.form-check {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.map-options {
  @media (max-width: 911px) {
    gap: 0px !important;
  }
  .zip-options {
    input {
      height: 42px;
      width: 240px;
    }

    @media (max-width: 911px) {
      width: 100% !important;

      .input-group {
        width: 100% !important;

        input {
          box-sizing: content-box !important;

        }
      }
    }
  }

  .power-options {
    .form-check {
      @media(max-width: 911px){
        label {
          margin-left: 12px !important;
        }
      }
    }
  }

  .form-group {
    @media(max-width: 911px){
      width: 100% !important;

      button {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
}