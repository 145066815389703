.incentives-sum-container {
  float: left;
  bottom: 16px;
  position: absolute;

  h2 {
    font-family: source-sans-pro, sans-serif;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 19.36px;
    text-align: left;
    color: #000;

    strong {
      font-weight: 700;
    }
  }

  @media (max-width: 991px) {
    position: relative;
    bottom: 0;
    margin-top: 16px;
  }
}