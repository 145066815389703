@import "src/client_customizations/scss/client_master.scss";


	.pageHeaderContainer {
		font-family: source-sans-pro, sans-serif;
		font-style: normal;
		text-align: center;

		>div {
            margin-top: 16px;
            margin-bottom: 16px;
		}

		.pageHeaderLead {
			font-weight: bold;
			font-size: 1rem;
			line-height: 1.5rem;
			text-transform: uppercase;
		}

		.pageHeaderHeadLine {
			font-weight: bold;
			font-size: 2.5rem;
			line-height: 3rem;
		}
	}
