.SelectedChargingStationCard {
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 15px;
  font-size: 0.75rem;
  width: 100%;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;

  .map-marker {
    margin: 0px 0px 24px 0px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    span {
      position: initial !important;
      transform: none !important;
    }

    p {
      margin: 0 0 0 16px;

      font-family: source-sans-pro, sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 13px;
      letter-spacing: 0em;
      text-align: left;

    }
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: #AEAEAE;
  }

  .plan-route {
    width: 100%;
    height: 40px;
    border: none;
    font-family: 'source-sans-pro' !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    color: #00657F;
    background-color: transparent;
    margin-top: 12px;

    @media(max-width: 991px) {
      border: 2px solid #00657F;
      margin-top: 24px;
    }
  }

  p {
    margin: 0 0 16px 0;
  
    font-family: source-sans-pro, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
  }
  
  a {
    margin: 0 0 16px 0;
    
    color: #006DFF;
    font-family: source-sans-pro, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: underline;
  }
}
