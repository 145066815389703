@import 'src/client_customizations/scss/client_master.scss';

#link-card-buttons {
	background: white;
}

.badge-fuel-type {
  width: auto;
  height: 24px;
  background: black;
  border-radius: 30%;
  display: flex !important;
  padding: 0px 4px;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}


.card-homepage {
  height: 164px;
  width: auto;
  margin: 0 75px;
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &.isMain {
    box-shadow: 0px 20px 41px 0px #0000001A;
    width: 262px !important;
    height: 296px !important;
    background: white;
    position: absolute; 
  }


  .icon {
    top: 0;
  }

  .copy{
    font-family: source-sans-pro, sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color:$main_blue;
    margin: 0;
    padding: 0;
  }
}

.homepage-jumbotron {
  padding-top: 62px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 91px;
  height: 600px;
  background-image: url('../../../client_customizations/assets/images/HomeBanner.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px -24px;
  
  @media (max-width: 991px) {
    background-position: bottom;
    padding-top: 0px;
    height: 350px;
    background-size: cover;
  }

  .homepage-jumbotron-text {
    text-align: center;
  }

  .banner-subsection {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 17px;
    width: 100%;
    height: 134px;
    flex-shrink: 0;
    background-color: rgba(148, 140, 242, 0.5);
    padding: 47px;

    @media (max-width: 992px) {
      row-gap: 20px;
      flex-direction: column;
      padding: 30px;
      height: inherit;
    }

    .banner-subsection-text {
      color: #ffffff;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px; /* 128.571% */

      @media (max-width: 992px) {
        font-size: 18px;
        text-align: center;
      }
    }
  }

  .title-banner {
    padding: 0 199px;
    color: $main_blue;
    text-align: center;
    font-family: source-sans-pro, sans-serif;
    font-size: 60px;
    font-weight: 700;
    line-height: 66px;
    text-align: center;

    text-transform: uppercase;

    @media (max-width: 992px) {
      font-size: 36px;
      line-height: 36px;
      padding: 25px 0 0;
      margin: 0;
    }
  }

  .title-banner-end {
    color: black;
    font-family: source-sans-pro, sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    text-transform: none;

    @media (max-width: 992px) {
      font-size: 16px;
      max-width: 39ch;
      display: flex;
    }
  }
}

#why-choose-evs {
  padding: 0rem 1rem;
}
.big-promise-caption {
  top: 0;
  left: 0;
  position: absolute;
  padding: 1.5rem 1rem;
}
#HomepageVehiclesCarousel {
  background-color: #f3f3f3;
}

.homepage-titles-container {
  display: flex;
  row-gap: 17px;
  flex-direction: column;
  width: 100%;

  @media (max-width: 991px) {
    gap: inherit;
    flex-direction: column;

    h1 {
      font-size: 36px !important;
    }

    p {
      font-size: 16px !important;
      margin-bottom: 46px !important;
    }
  }
    
}

.yellow-bar {
  background: #D1AF1E;
  width: 100px;
  height: 5px;
  margin-bottom: 24px;

  &.map {
    margin-top: 0px !important;
    margin-bottom: 60px !important;
  }
}

.max-width {
  max-width: 950px;
}

.HomepageSubTitle {
  font-size: 20px;
  font-weight: 400;
  text-align: center; 
  margin-bottom: 100px;
  @media (max-width: 991px) {
    width: 95%;
    text-align: center;
  }
}

.primary {
  color: $main_blue;
  font-family:  source-sans-pro, sans-serif;
  font-size: 40px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; 
  text-align: center;
  text-transform: uppercase;
}

.secondary {
  color: #00657F;
  font-weight: 500 !important;
}

.HomepageFaq {
  padding: 75px 0;
  display: flex;
  background-color: #f8f8f8;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 5rem 17rem;
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: 992px) {
    margin: 2.5rem 2.5rem;
  }

  .HomepageFaqTitle {
    width: 65%;
    display: flex;
    gap: 10px;

    .questions {
      color: black;
      font-family: source-sans-pro;
    }

    .answered {
      font-family: source-sans-pro;
      font-weight: 600;
    }

    @media (max-width: 766px) {
      gap: inherit;
      flex-direction: column;
    }
  }

  .HomepageFaqSubTitle {
    width: 65%;
    font-size: 20px;
    font-weight: 400;
    font-family: source-sans-pro;

    @media (max-width: 766px) {
      width: 95%;
      text-align: center;
    }
  }

  .MuiAccordion-root {
    width: 70% !important;
    background-color: #f8f8f8;
    box-shadow: inherit;
    border-radius: inherit !important;
    border-top: 1px solid #576116;
  }
}

.container-home {
  padding: 5rem 17rem;

  @media (max-width: 992px) {
    padding: 2.5rem 2.5rem;
  }
}

.homepage-incentives {


  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  row-gap: 49px;
  padding: 100px 0rem 100px 0rem;

  @media (max-width: 991px) {
    padding: 46px 0;
    row-gap: 46px;
  }
}

.homepage-incentives-title-container {
  display: flex;
  row-gap: 17px;
  max-width: 1200px;
  flex-direction: column;
  @media (max-width: 992px) {
      margin: 0 16px;
    }
}

.homepage-incentives-title {
  color: $main_blue;

  font-family: source-sans-pro, sans-serif;
  font-size: 40px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 119.149% */
  text-transform: uppercase;
  text-align: center;

  .highline {
    font-weight: 500 !important;
  }
}

.homepage-incentives-subtitle-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage-incentives-title-sub {
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; 
  text-align: center;
}

.homepage-incentives-bar {
  background-color: rgba(221, 240, 252, 0.5);
  mix-blend-mode: screen;
  height: 112px;
  width: 100%;
}

.homepage-incentives-list {
  display: flex;
  flex-direction: column;
  
}

.homepage-incentives-cards {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 16px;
  row-gap: 16px;
  max-width: 824px;
  justify-content: flex-start;

  @media (max-width: 991px) {
    justify-content: center;
  }
}

.homepage-incentives-sort {
  max-width: 813px;
  display: flex;
}

// flex-wrap: wrap;
// width: 100%;
// align-items: center;
// justify-content: center;

// column-gap: 16px;
// row-gap: 16px;
// max-width: 1100px;
// margin:0 auto;



.homepage-home-charger-banner {
  background-color: #00657F;
  padding-bottom: 85px;
}

.homepage-home-charger-title-container {
  display: flex;
  flex-direction: column;
  row-gap: 17px;
  padding: 5.3rem 0rem 3.75rem 0rem;
  max-width: 1100px;
  margin:0 auto;

  @media (max-width: 992px) {
    padding: 2.5rem 2.5rem;
  }
}

.homepage-home-charger-title {
  color: #ffffff;
  font-family: source-sans-pro;
  font-size: 47px !important;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
  max-width: 877px;

  .highline {
    font-family: source-sans-pro;
    font-size: 47px;
    font-weight: 500;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.homepage-home-charger-title-sub {
  font-family: source-sans-pro, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
}

.homepage-home-charger-get {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -9px 0 90px 0;

  @media (max-width: 991px) {
    margin: 0;
  }

  .btn-incentives-homepage {
    width: 252px;
    height: 48px;
    gap: 0px;
    background: #00657F;
    color: white;
    border: none;
    font-family: source-sans-pro, sans-serif;
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    text-transform:uppercase;
  }
}

.incentives-cards-container {
  display: flex;
  gap: 32px;
  justify-content: center;

  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
}

.home-map-section-container {
       padding:0;
        background: #F4F4F4;

      .home-map-text-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

       .home-map-section {
              max-width:1100px;
              padding: 100px 0 5rem;
              margin:0 auto;

              &.page {
                padding: 0px 0px 5rem !important; 
              }

              @media(max-width: 992px) {
                padding: 48px 16px;
              }
       }
}
