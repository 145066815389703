@import 'src/client_customizations/scss/client_master.scss';

.legal-container {
  height: 140px;
  width: 100%;
  display: flex ;
  flex-direction: column;
  background-color: #143948;
  padding: 60px;
  align-items: center;
  justify-content: center;

  color: #fff;

  @media (max-width: 991px) {
    height: auto;
    padding: 16px;
  }


  .zappy-container {
    display: flex;
    img {
      margin: 0 4px 0 8px;
    }
    p {
      padding: 0;
      margin: 0;
    }

    margin-bottom: 8px;
  }
}

.Footer {
  display: flex !important;
  flex-direction: row !important;
  background-color: #00657F;
  padding: 60px;
  color: $GenericFooter-color;
  align-items: flex-start !important;
  gap: 85px;

  .logo {
    filter: brightness(0) invert(1);
  }

  .address-column {
    display: flex;
    flex-direction: column;

    span {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    ul {
      font-size: 14px;
      font-weight: 400;
      list-style-type: none;
      margin: 0;
      padding: 0;
      gap: 12px;

      li {
        margin: 0 0 8px 0;
      }
    }

    &.list {
      @media (max-width: 991px) {
        margin: 24px 0px;
      }
    }
  }

  @media (max-width: 991px) {
    padding: 24px 42px;
    flex-direction: column !important;
    .icon-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      margin-bottom: 42px;
    }
  }

  .disclaimer_container {
    font-family: Arial, Helvetica;
    display: flex;
    align-items: center;
    margin-left: '5px';
    font-size: 10px; /* Set the font size to 10px */
    max-width: 900px;

    div {
      background-color: transparent !important;

      img {
        max-width: 50%;
        margin: 0 0 15px;
      }

      p {
        color: white;
      }
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;

  .subtext {
    font-family: source-sans-pro !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 24px; /* 171.429% */
    text-transform: uppercase;
  }

  .social-media-row {
    display: flex;
    flex-direction: row;
    column-gap: 24px;
  }

  .legal-info {
    text-align: center;
  }
}
