.MatchScoreOptions {
  .extra-class {
    font-family: source-sans-pro, sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 25.14px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    color: #2A2A2A;

  }
  .h2 {
       font-family: "source-sans-pro, sans-serif, Open Sans", Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
  }
}