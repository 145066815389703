.MapControlPanel {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 991px) {
    height: auto;
  }

  .map-controls {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0;

    .form-group {
      padding-right: 10px;
      padding-left: 10px;

      &:first-child {
        padding-left: 0;
      }

      &.last-item {
        padding-right: 0;
      }

      &.checkbox {
        margin-top: 24px;
      }

      label {
        font-family: source-sans-pro, sans-serif !important;
        font-weight: 700;
        font-size: 12px;
      }

      button {
        background-color: #00657F;
        border: none;

        font-family: 'source-sans-pro', source-sans-pro, sans-serif, 'Segoe UI' !important;
        font-weight: 700;
        font-size: 16px;
        color: #ffffff;
        text-transform: uppercase;

        width: 100%;
        height: 42px;

        padding: 0;
        margin: 18px 0 0;
      }

      @media (max-width: 991px) {
        padding: 0 0;
      }
    }

    @media (max-width: 991px) {
      .form-group {
        width: 100%;
        input {
          width: 100%;
        }

        &.checkbox {
          input {
            width: 24px;
          }

          .label {
            margin: 4px 0 0 14px;
          }
        }
      }
    }
  }

  @media (min-width: 576px) {
    .map-controls {
      display: flex;
      align-items: center;
    }
  }

  @media (max-width: 991px) {
    margin-top: 28px;
  }

  .form-check label {
    font-weight: 700;
    font-size: 12px;
    color: #666666;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}
