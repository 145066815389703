.modal-wrapper {
    .fire-button {
        width: 100%;
        height: 54px;

        background: #00657F;

        font-family: source-sans-pro;
        font-size: 17px;
        font-weight: 700;
        line-height: 20px;
        text-align: center;
        color: #fff;
        border: none;

    }
    
}

.fullscreen {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    border: 0;
    overflow: hidden;

    .modal-content {
        width: 100% !important;
        height: 100% !important;
        background: #F4F4F4;

        border: 0px; 
        border-radius: 0px;
        overflow: scroll;


        .modal-header {
            background: #00657F;
            color: #fff;

            .modal-title {
                font-family: source-sans-pro, sans-serif;
                font-size: 16px;
                font-weight: 700;
                line-height: 32px;
                text-align: left;
            }

            .close {
                color: #fff !important;
                opacity: 1;
                text-shadow: 0;
            }
        }

        .modal-body {
            .form-group {
                background: #F4F4F4;
            }
        }
    }
}

