.incentives-page {

  @media (max-width: 991px) {
    padding: 0 !important;
  }
  
}

.evHead {
  font-family: source-sans-pro, sans-serif;
  font-size: 46px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
}

.yellow-bar {
  background: #D1AF1E;
  width: 100px;
  height: 5px;
  margin-top: 16px;
}

.lead {
  font-family: source-sans-pro, sans-serif;
  font-size: 28px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;

  max-width: 931px;
  margin: 0 auto 50px;
}

.disclaimer {
  margin-top: 16px;

  font-family: source-sans-pro, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.main-page-container {
  display: flex;
  margin-top: 70px;
  justify-content: center;
}

.cards-container {
  padding-left: 10px; 
  margin-top: -90px;
}