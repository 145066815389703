@import "src/client_customizations/scss/client_master.scss"; 

.PricePanels {
	background-color: $PricePanels-background-color;
	border: 1px solid #AEAEAE;
	.bottom-container {
		width: 100%;
		border-top: 1px solid #AEAEAE;
		padding: 16px 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.bottom-item {
			display: flex;
			flex-direction: column;

			h3 {
				font-family: source-sans-pro;
				font-size: 12px;
				font-weight: 400;
				line-height: 20px;
				text-align: left;
				color: #595959;
			}

			.h3  {
				font-family: source-sans-pro;
				font-size: 12px;
				font-weight: 400;
				line-height: 20px;
				text-align: left;
				color: #595959;
			}

			span {
				font-family: source-sans-pro;
				font-size: 24px;
				font-weight: 400;
				line-height: 30.17px;
				letter-spacing: 1.4642857313156128px;
				text-align: left;
				color: #2A2A2A;

			}
		}
	}
	.Nav {
		border-bottom: $PricePanels-Nav-border;
		margin: 0;
		padding: 0;
	}
	li {
		a {
			font-size: $PricePanels-li-a-font-size;
			color: $PricePanels-li-a-font-color;
			cursor: pointer;
			margin-top: 16px;
			margin-bottom: 10px;
			span {
				font-weight: 400;
			}
			&:hover {
				text-decoration: none;
			}
		}
		a.active {
			span {
				padding-bottom: 13px;
				border-bottom: $PricePanels-li-a-span-border_active;
			}
		}
	}
	.nav-pills {
		.nav-link.active {
			background-color: $PricePanels-nav-pills-nav-link-background-color_active;
			color: $PricePanels-nav-pills-nav-link-font-color_active;
		}
	}
	.tab-pane {
		padding: 0;
		.PanelItem {
			border-top: $PricePanels-tab-pane-PanelItem-border;
			padding: 15px;
		}
		.PanelItemTop {
			display: inline-block;
			text-align: center !important;
		}
	}
}
.MatchScoreBadge {
	text-align: center;
	background-color: $MatchScoreBadge-background-color;
	padding: 5px;
	border: $MatchScoreBadge-border;
	margin-top: 15px;
}
