.CostOfOwnership {
       padding-top: 50px;

       @media (max-width: 991px) {
              padding-top: 0px;

              font-family: source-sans-pro;
              font-size: 24px;
              font-weight: 400;
              line-height: 30.17px;
              text-align: center;

       }
}

#CostOfOwnership {
       background: #F4F4F4;
       .graph-container {
              margin-top: 16px;

              .input-well  {
                     background: #fff;
                     border: 1px solid #DBDBDB
              }
       }
}