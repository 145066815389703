.dicover-section {
  padding-top: 56px;
  padding: 30px;
  padding-bottom: 100px;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 80px;
  width: 100%;
  background-color: #F4F4F4;

  @media (max-width: 991px) {
    row-gap: 48px;
    padding-bottom: 48px;
  }
}

.dicover-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  max-width: 1100px;

  margin-top: 129px;

  @media (max-width: 991px) {
    margin-top: 0px;

    a {
      width: 100%;
      height: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.text-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: uppercase;
}

.title-head {
  color: #00657F;
  font-family: source-sans-pro, sans-serif;
  font-size: 40px !important;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
  margin: 0;
  padding: 0;
  text-align: center;
  @media (max-width: 991px) {
    font-size: 36px !important;
  }
}

.title-head-discover {
  color: #00657F;
  font-family: source-sans-pro, sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
  margin: 0;
  padding: 0;

  @media (max-width: 991px) {
    font-size: 36px;
  }
}

.title-sub {
  color: #000;
  font-family: source-sans-pro, sans-serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  
  text-align: center;

  @media (max-width: 991px) {
    font-size: 16px;
    line-height: 18px;
  }

}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.car-list {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: nowrap;
  overflow: hidden;
  max-width: 1100px;
}

.car-list > div {
  flex: 0 0 auto;
  width: 250px;
}

.arrow-container {
  width: auto;
  height: auto;
  opacity: 0.5;
  cursor: not-allowed;
  border: none;

  &.left {
    transform: rotate(180deg);
  }

  &.enabled {
    opacity: 1;
    cursor: pointer;
  }
}

@media (max-width: 991px) { /* Ajustando para telas menores que 919px */
  .carousel-container {
    max-width: 100%;
  }

  .car-list{
    max-width: 100%;
  }
}