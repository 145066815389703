.carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .incentive-list {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    row-gap: 15px;
    flex-wrap: nowrap;
    overflow: hidden;
    max-width: 1100px;
  }
  
  .incentive-list > div {
    flex: 0 0 auto;
    width: 250px;
  }
  
  .arrow-container {
    width: auto;
    height: auto;
    opacity: 0.5;
    cursor: not-allowed;
    border: none;
    background: #fff;
  
    &.left {
      transform: rotate(180deg);
    }
  
    &.enabled {
      opacity: 1;
      cursor: pointer;
    }
  }
  
  @media (max-width: 991px) { 
    .carousel-container {
      max-width: 100%;
    }
  
    .incentive-list{
      max-width: 100%;
      align-items: center;
      justify-content: center;
    }
  }