.TravelRadiusMap .map-container {
  position: relative;
  height: 552px;
  width: 100%;
  @media (min-width: 992px) {
      width: 135%;
    }
}

.TravelRadiusMap .VehicleRangeLegend {
  background: black;
  padding: 0.5em;
  position: absolute;
  z-index: 1;
  font-size: 0.75em;
  color: white;
  bottom: 0;
  left: 0;
}

.TravelRadiusMap .VehicleRangeLegend .range-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.25em;
}

.TravelRadiusMap .range-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 0.5em;
}
.TravelRadiusMap .range-icon-total {
  border: 2px solid #D1AF1E;
  background-color: #D1AF1E;
}

.TravelRadiusMap .range-icon-electric {
  border: 2px solid #00657F;
  background-color: #00657F;
}

.TravelRadiusMap label {
  font-family: source-sans-pro, sans-serif;
  text-transform: capitalize;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
  color: #A0A0A0;
  margin-bottom: 8px;
}



.title {
  font-size: 14px;
  margin-bottom: 1rem;
  font-weight: bold;
}