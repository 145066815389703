@import "src/client_customizations/scss/client_master.scss";

.link-card-container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	max-width: 400px;
	margin: 0 auto;

	@media(max-width: 991px) {
		margin: 0;
		width: 100%;
		max-width: 100%;
		align-items: center;
		justify-content: space-between;
		
	}
}
.link-card {
	display: flex;
	text-align: center;
	flex-shrink: 1;
	flex-basis: 80px;
	position: relative;
	cursor: pointer;



	.icon {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		width: auto;
		height: auto;
		margin-bottom: 20px;
		background-color: $main_blue;
		mask-repeat: no-repeat;
		mask-position: center;
		mask-size: contain;

		
		@media(max-width: 991px) {
			width: 100% !important;
			height: auto;
			min-height: 42px;
			max-height: 56px;
		 }
	}
	p {
		margin-top: 0.5rem;
		margin-bottom: 0;
		text-decoration: none;
		color: $main_blue;
		font-family: source-sans-pro, sans-serif;
		font-size: 28px !important;
		font-weight: 400;
		line-height: 36px;
		text-align: center;

	}

	.evc-card {
		transition: .1s ease-in-out;
		background-color: $LinkCard-evc-card-background-color;
		border: 1px solid transparent;
		padding-top: 40px;
		padding-bottom: 40px;
		border-radius: 0px;


		@media (max-width: 991px){
			width: 95px !important;
		}

		p {
			font-size: 0.7rem;

			 @media(max-width: 991px) {
				font-size: 16px !important;
				color: #2A2A2A;
				font-weight: 400;
				line-height: 16px;
			 }
		}
		> div {
			transition: .1s ease-in-out;
		}
		&:hover {
			transform: scale(1.242, 1.352);
			position: absolute;
			box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.15);

			> div {
				transform: translateY(-10px);
			}
			
		}
	}
}
@media (min-width: 576px) {
	.link-card-container {
		max-width: 42%;
	}
	.link-card {
		flex-basis: 120px;
		.icon {
			width: 100%;
			height: 45px;
		}
		.evc-card {
			p {
				font-size: 0.85rem;
			}
		}
	}
}
@media (min-width: 768px) {
	.link-card {
		flex-basis: 211px;
	}
}
