@import "src/client_customizations/scss/client_master.scss";

.title-header{
       font-family: source-sans-pro;
       font-size: 46px;
       font-weight: 700;
       line-height: 50px;
       text-align: center;
       text-transform: uppercase;
}

.vehicle-type-filter {
       width: 100%;
       height: 128px;
       padding: 8px 0px 0px 0px;
       gap: 16px;
       border-radius: 5px;
       border: 1px solid #AEAEAE;
       background-color: #fff; 
       padding: 8px;    
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: space-between;
       margin-top: 94px;

       @media (max-width: 991px) {
              margin: 0;
       }

       button {
              width: 100%;
              height: 48px;
              padding: 16px 10px 16px 10px;
              border-radius: 5px;
              font-family: source-sans-pro;
              font-size: 17px;
              font-weight: 700;
              line-height: 20px;
              text-align: center;
              border: none;
              cursor: pointer;
       }
       
       #new-vehicle {
              background: #00657F;
              color: white;
       }

       #used-vehicle {
              background: #fff;
              color: black;
       }
}

.container-row {
       display: flex;
       align-items: center;
       justify-content: center;

       span {
              width: 100px;
              height: 5px;
              background: #D1AF1E;
              margin-top: 17px;
       }
}

.small-centered.mb-bottom {
       margin-bottom: 50px;
}

.EVsSort {
       margin-bottom: 15px;

       label {
              font-weight: $EVsSort-label-font-weight;
              font-size: $EVsSort-label-font-size;
              color: $EVsSort-label-color;
              letter-spacing: $EVsSort-label-letter-spacing;
              line-height: $EVsSort-label-line-height;
       }
       select {
              background: $EVsSort-select-background;
              border: $EVsSort-select-border;
              border-radius: $EVsSort-select-border-radius;
              font-size: $EVsSort-select-font-size;
              color: $EVsSort-select-color;
              letter-spacing: $EVsSort-select-letter-spacing;
       }

       @media (max-width: 991px) {
              width: 100%;
              margin-top: 16px;
              label {
                     font-family: source-sans-pro;
                     font-size: 14px !important;
                     font-weight: 400 !important;
                     line-height: 17.6px !important;
                     text-align: left !important;
                     color: #1A1A1A !important;

              }
       }

       .form-inline {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;

              @media (max-width: 991px) {
                     width: 100%;
              }

              select {
                     width: 100% !important;
              }
       }
}

.ev-toggle {
       @media (min-width: 576px) {
              width: 60%;
       }
       padding: 0;
       display: flex;
       align-items: center;
       background-color: #fff;
       margin: 50px
              0
              35px;
       margin-left: auto;
       margin-right: auto;
       box-shadow: 0px
              20px
              41px
              rgba(
                     0,
                     0,
                     0,
                     0.1
              );

       a {
              @media (min-width: 576px) {
                     width: 50%;
              }

              text-align: center;
              color: #2a2a2a;
              text-transform: uppercase;
              background-color: #fff;
              padding: 20px
                     0px;
              font-size: 16px;
              font-weight: 600;
              transition: all
                     0.2s
                     ease-out;

              &.active {
                     background: #0081c6;
                     color: #fff;
              }

              &:hover {
                     text-decoration: none;
                     // background-color:#E3001C;
                     // color:#fff;
              }
       }
}

@media (min-width: 576px) {
       .EVsSort {
              float: right;
       }
}
